import {fetchAd, fetchMakeModelBySlug} from "../../api";

export default {
    fetchAd({commit}, payload) {
        return fetchAd(payload).then(item => {
            if(item.toString().startsWith('Error') || item.toString().startsWith('AxiosError')){
                commit("setError404", true);
            }else{
                commit("setError404", false);
            }
            payload.item = item;
            commit("setAd", payload);
        })
    },
    fetchMakeModelBySlug({commit}, payload){
        if(!payload.make || !payload.model){
            return false;
        }
        let url = process.env.VUE_APP_API_URL + '/api/get-make-model-by-slug/' + payload.make.toLowerCase() + '/' + payload.model.toLowerCase();
        return fetchMakeModelBySlug(url).then(item => {
            commit('setMake404', item.make);
            commit('setModel404', item.model);
        });
    },

};
